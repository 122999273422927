<script setup>
import {
    Sheet,
    SheetContent,
    SheetDescription,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from '@/components/ui/sheet'

const props = defineProps({
    items: {
        type: Array,
        required: true,
    },
});
</script>

<template>
    <Sheet>
        <SheetTrigger aria-label="Menü" class="relative">
            <slot name="trigger" />
        </SheetTrigger>
        <SheetContent>
            <SheetHeader>
                <SheetTitle>
                    <slot name="title" />
                </SheetTitle>
                <SheetDescription>
                    <span class="sr-only">Mobile Navigation</span>
                </SheetDescription>
            </SheetHeader>

            <template #close>
                <slot name="close" />
            </template>

            <div className="space-y-6 border-gray-200 px-4 py-6">
                <div v-for="navLink in items" :key="navLink.url" className="flow-root">
                    <a :key="navLink.url" :href="navLink.url" className="text-lg text-secondary">
                        {{ navLink.title }}
                    </a>
                </div>
            </div>

            <div className="space-y-6 border-t border-gray-200 px-4 py-6">
                <div className="flow-root"></div>
            </div>
        </SheetContent>
    </Sheet>
</template>